const Frkeys: any = {
  'self-storage': 'entreposage-libre-service',
  business: 'commercial',
  residential: 'residentiel',
  moving: 'demenagement',
  'packing-supplies': 'fournitures-emballage',
  'portable-storage': 'entreposage-mobile',
  'business-storage': 'entreposage-commercial',
  'long-distance-moving': 'demenagement-longue-distance',
  about: 'a-propos',
  faq: 'faq',
  'contact-us': 'nous-contacter',
  offers: 'offres',
  'terms-of-service': 'conditions-dutilisation',
  'privacy-policy': 'politique-de-confidentialite',
  'community-resources': 'ressources-communautaires',
  'supporting-team-canada': 'soutenir-equipe-canada',
  'moving-centre': 'centre-demenagement',
  careers: 'carrieres',
  'valet-service': 'service-de-valet',
  blog: 'blogue',
  'extra-storage-space': 'plus-espace-entreposage',
  'home-staging': 'mise-valeur-propriete',
  'home-staging-storage': 'mise-en-valeur-de-propriete',
  'supporting-canada-soccer': 'soutenons-canada-soccer',
  'team-canada': 'equipecanada',
  'car-storage': 'entreposage-pour-automobile',
  'motorcycle-storage': 'entreposage-pour-moto',
  'rv-storage': 'entreposage-pour-rv',
  privacy: 'confidentialite',
  'student-special-2022': 'promotion-etudiante-2022',
  'construction-supplies': 'fournitures-construction',
  'automobile-storage': 'entreposage-automobile',
  'self-storage-features': 'caracteristiques-entreposage-libre-service',
  'heated-self-storage-units': 'unites-d-entreposage-chauffees',
  'retail-inventory': 'commerce-detail',
  'drive-up-units': 'unites-d-entreposage-avec-acces-routier',
  'a-world-without-ms': 'un-monde-sans-sp',
  'student-storage': 'entreposage-pour-etudiants',
  'unit-guide': 'guide-unitaire',
};
export default Frkeys;
