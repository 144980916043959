export const SITE_LINK = `https://www.smdservers.net/SLWebSiteTemplate_V2/login.aspx?`;
export const ORDER_LINK = `https://www.movebuddy.com/shop/access-storage`;
export const FOOTER_ACCESIBILITY = `https://www.storagevaultcanada.com/en/accessibility`;
export const FOOTER_STORAGE_CANADA = `https://www.storagevaultcanada.com/en/corporate-governance`;
export const PACKING_SUPPLIES_SHOP = 'https://shop.accessstorage.ca';

export const ABOUT_CMS_SLUG = 'about-us';
export const PACKING_SUPPLIES_CMS_SLUG = 'packing-supplies';
export const CONTACT_US_CMS_SLUG = 'contact-us';
export const PRIVACY_POLICY_CMA_SLUG = 'privacy-policy';
export const USER_AGREEMENT_CMS_SLUG = 'user-agreement';
export const SMALL_BUSINESS_CMS_SLUG = 'small-business';
export const SMALL_BUSINESS_CMS_SLUG_FR = 'entreprises';
export const CAREER_CMS_SLUG = 'careers';
export const CAREER_CMS_SLUG_FR = 'carrieres';
export const PORTABLE_MOVING = 'portable-storage-moving';
export const PORTABLE_LONG_DISTANCE = 'portable-storage-long-distance-moving';
export const PACKING_SUPPLIES_CMS_SLUG_FR = 'fournitures-d-emballage';
export const ABOUT_CMS_SLUG_FR = 'a-propos-de-nous';
export const CONTACT_US_CMS_SLUG_FR = 'nous-contacter';
export const PRIVACY_POLICY_CMA_SLUG_FR = 'politique-de-confidentialite';
export const USER_AGREEMENT_CMS_SLUG_FR =
  'entente-sur-les-conditions-d-utilisation';
export const PORTABLE_MOVING_FR = 'entreposage-mobile-moving';
export const PORTABLE_LONG_DISTANCE_FR =
  'entreposage-mobile-demenagement-sur-longue-distance';
export const FIND_STORE_LINK = 'https://www.accessstorage.ca/en/self-storage/';
export const SELF_STORAGE_MOVING = 'self-storage-moving';
export const SELF_STORAGE_MOVING_FR = 'demenagement';
export const VALET_SERVICE = 'valet-service';
export const VALET_SERVICE_FR = 'service-de-valet';
export const PORTABLE_BUSINESS = 'portable-storage-business';
export const PORTABLE_BUSINESS_FR = 'entreposage-mobile-commercial';
export const PORTABLE_RESIDENTIAL = 'portable-storage-residential';
export const PORTABLE_RESIDENTIAL_FR = 'entreposage-portatif-residentiel';
export const HOME_RENOVATION = 'Home Renovations';
export const HOME_RENOVATION_FR = 'Rénovation résidentielle';
export const SELF_STORAGE_RESIDENTIAL = 'residential';
export const SELF_STORAGE_RESIDENTIAL_FR =
  'entreposage-sibre-service-residentiel';
export const TEAM_CANADA = 'team-canada';
export const TEAM_CANADA_FR = 'equipecanada';
export const SUPPORTING_TEAM_CANADA = 'supporting-team-canada';
export const SUPPORTING_TEAM_CANADA_FR = 'soutenir-equipe-canada';
export const TEAM_CANADA_SOCCER = 'supporting-canada-soccer';
export const TEAM_CANADA_FR_SOCCER = 'soutenons-canada-soccer';
export const WORLD_WO_MS = 'a-world-without-ms';
export const WORLD_WO_MS_FR = 'un-monde-sans-sp';
export const SELF_STORAGE_SIZE_GUIDE = 'size-guide';
export const SELF_STORAGE_SIZE_GUIDE_FR = 'guide-des-tailles';
export const GOOGLE_SITE_LINK_KEY = '6LcBBVshAAAAAFnc1NHUO5gRTJibFV_vtSQ3EMaG';
export const COMMUNITY_GIVING = 'community-connection';
export const COMMUNITY_GIVING_FR = 'connexion-communautaire';
export const RETAIL_INVENTORY = 'retail-inventory';
export const RETAIL_INVENTORY_FR = 'commerce-de-detail';
export const HOME_STAGING_STORAGE = 'home-staging-storage';
export const HOME_STAGING_STORAGE_FR = 'mise-en-valeur-de-propriete';
export const CONSTRUCTION_SUPPLY = 'supply-and-tool-storage';
export const CONSTRUCTION_SUPPLY_FR = 'entreposage-d-outils-et-de-fournitures';
export const AUTOMATION_SELF = 'automobile-self-storage';
export const AUTOMATION_SELF_FR = 'entreposage-automobile-en-libre-service';
export const RECAPTCHA_CALLMEBACK = 'callmeback';
export const RECAPTCHA_CONTACT_US = 'contact';
export const RECAPTCHA_SMALL_BUSINESS = 'smallbusiness';
export const RECAPTCHA_TEAM_CANADA = 'teamcanada';
export const RECAPTCHA_CHAT = 'chatcallback';
export const UNIT_FREQUENCY = 'week';
export const PORTABLE_UNIT_FREQUENCY = 'every 4 weeks';
export const DEFAULT_FACILITY_ID = 'L002';
export const BLOG = 'blog';
export const BLOG_FR = 'blog-fr';
export const SELF_STORAGE = 'self-storage';
export const SELF_STORAGE_FR = 'self-storage-fr';
export const LOCATIONS_DISTANCE = 20;
export const SUCCESS_CODE = 200;
export const UNITS_LEFT = 5;
export const MINIMUM_UNITS = 1;
export const HIGH_IN_DEMAND = 30;
export const BRAND_NAME_EN = 'as';
export const BRAND_NAME_FR = 'as-fr';

export const MOTOR_CYCLE = 'motorcycle-storage';
export const MOTOR_CYCLE_FR = 'entreposage-pour-moto';
export const RV_STORAGE = 'rv-storage';
export const RV_STORAGE_FR = 'rv-storage-fr';
export const TRUCK_STORAGE = 'truck-storage';
export const TRUCK_STORAGE_FR = 'truck-storage-fr';
export const EXTRA_STORE_SPACE = 'extra-storage-space';
export const EXTRA_STORE_SPACE_FR = 'plus-d-espace-d-entreposage';
export const FAQ_EN = 'faq';
export const FAQ_FR = 'faq-fr';
export const SITE_MAP_EN = 'sitemap';
export const SITE_MAP_FR = 'sitemap-fr';
export const PS_FAQ = 'faq-ps';
export const SELF_STORAGE_FAQ = 'self-storage';
export const SELF_STORAGE_FAQ_FR = 'self-storage-fr';
export const PORTABLE_FAQ = 'portable-storage';
export const PORTABLE_FAQ_FR = 'portable-storage-fr';
export const PS_FAQ_FR = 'faq-ps-fr';
export const BOAT_STORAGE = 'boat-storage';
export const BOAT_STORAGE_FR = 'boat-storage-fr';
export const CLIMATE_CONTROLLED_STORAGE = 'climate-controlled-storage';
export const CLIMATE_CONTROLLED_STORAGE_FR = 'climate-controlled-storage-fr';
export const HEATED_STORAGE = 'heated-self-storage-units-as';
export const HEATED_STORAGE_FR = 'unites-d-entreposage-chauffees';
export const DRIVE_UP_UNITS = 'drive-up-units';
export const DRIVE_UP_UNITS_FR = 'unites-d-entreposage-avec-acces-routier';
export const CAR_STORAGE = 'car-storage';
export const CAR_STORAGE_FR = 'car-storage-fr';
export const STUDENT_STORAGE = 'student-storage';
export const STUDENT_STORAGE_FR = 'entreposage-pour-etudiants';
export const RECRETIONAL_VEHICLE = 'recreational-vehicles';
export const RECRETIONAL_VEHICLE_FR = 'recreational-vehicles-fr';
export const SELF_STORAGE_FEATURES = 'self-storage-features';
export const SELF_STORAGE_FEATURES_FR =
  'caracteristiques-entreposage-libre-service';
export const DEFAULT_SITE_LINK = 'hqFZTTuWsckQxgIDaX4ymw';
export const SEARCH_PLACEHOLDER_EN = 'Search by address, city, or postal code';
export const SEARCH_PLACEHOLDER_FR = 'Adresse, ville ou code postal';
export const SEARCH_PLACEHOLDER_EN_MOB = 'Search by address';
export const SEARCH_PLACEHOLDER_FR_MOB = 'Adresse';
export const SITE_TITLE = 'Access Storage';
export const SITE_URL = 'https://www.accessstorage.ca/';
export const SVG_CONSTANT = `<svg width="95" height="37" viewBox="0 0 95 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_428_1695)">
<rect x="4" width="87" height="29" rx="14.5" fill="#031232"/>
</g>
<defs>
<filter id="filter0_d_428_1695" x="0" y="0" width="95" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_428_1695"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_428_1695" result="shape"/>
</filter>
</defs>
</svg>`;
export const SVG_RED_PIN = `<svg width="95" height="37" viewBox="0 0 95 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_265_77)">
<rect x="4" width="87" height="29" rx="14.5" fill="#e2002d"/>
</g>
<defs>
<filter id="filter0_d_265_77" x="0" y="0" width="95" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_265_77"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_265_77" result="shape"/>
</filter>
</defs>
</svg>`;

export const RECAPTCH_URL = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_SITE_LINK_KEY}`;
export const CUSTOME = 'custome';
export const PARKING_TEXT = 'Parking';
export const PARKING_TEXT_FR = 'Stationnement';
export const DESC_FORM_ERR = 'Description is required';
export const DESC_FORM_ERR_FR = 'Veuillez remplir ce champ';
export const NAME_FORM_ERR = 'Full name is required';
export const NAME_FORM_ERR_FR = 'Nom au complet requis';
export const EMAIL_FORM_ERR = 'Email is required';
export const EMAIL_FORM_ERR_FR = 'Veuillez respecter le format demandé';
export const PHONE_FORM_ERR = 'Phone number is required';
export const PHONE_FORM_ERR_FR = 'Veuillez respecter le format demandé';
export const REFER_FORM_ERR = 'Please select an item from the list';
export const REFER_FORM_ERR_FR = 'Veuillez choisir un élément dans la liste';
export const ENTER_CHARS_FORM_ERR = 'Enter characters';
export const ENTER_VALID_FORM_ERR = 'Enter valid format';
export const ENTER_PHONE_FORM_ERR = 'Enter valid phone number';
export const STORE_NAME = 'Access Storage';
export const PHONE_NUMBERS = {
  VALET_SERVICE: '855-506-1114',
  PORTABLE_STORAGE: '884-897-3811',
  SELF_STORAGE: '888-383-7721',
};
export const STUDENT_SPECIAL = 'student-special-2022';
export const STUDENT_SPECIAL_FR = 'promotion-etudiante-2022';
export const SUCCESS_IMG =
  'https://static.accessstorage.ca/assets/img-access-thankyou.svg';

export const PORTABLE_CALL_NUMBER = '1-855-956-7766';
export const GOOGLE_MAP_API = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`;

export const PREFERS_CONTACT_BY = 'phone';

export const FORM_ERRORS = {
  EN: {
    FULLNAME: 'Full name is required',
    EMAIL: 'Email is required',
    PHONE: 'Phone number is required',
    MOVEIN_DATE: 'Move-in date is required',
    ENTER_CHARS: 'Enter characters',
    ENTER_VALID: 'Enter valid format',
    ENTER_PHONE: 'Enter a valid phone number',
    REQUIRED: 'Required',
  },
  FR: {
    FULLNAME: 'Nom au complet requis',
    EMAIL: 'Courriel requis',
    PHONE: 'No de tél requis',
    MOVEIN_DATE: 'Date d’emménagement requise',
    ENTER_VALID: 'Veuillez respecter le format demandé',
    REQUIRED: 'Requis',
  },
};
export const PLACEHOLDERS = {
  EN: {
    FULLNAME: 'Full Name*',
    EMAIL: 'Email*',
    PHONE: 'Phone*',
    MOVEIN_DATE: 'Move-in date*',
    MESSAGE: 'Message',
  },
  FR: {
    FULLNAME: 'Nom au complet*',
    EMAIL: 'Courriel*',
    PHONE: 'No de tél*',
    MOVEIN_DATE: 'Date d’emménagement*',
    MESSAGE: 'Message',
  },
};
export enum PageType {
  StoreLocatorPage,
  CityLandingPage,
  FacilityPage,
}
export const LIMIT_NUMBER_OF_BLOGS = 50;

export const HALIFAX_LAT_LANG = { lat: 44.6475811, lng: -63.5727683 };
